<template>
	<div class="" style="min-width: 1200px">
		<sticky :sticky-top="80" >
			<div class="header-container" style="height: 50px; width: 100%; background-color: #f9f9f9">
				<div class="search-container-fn-input">
					<el-select v-model="choiceDepartment" clearable
										 placeholder="请选择" size="small" @change="handleSelectDepartment">
						<template slot="prefix">
							<i class="iconfont icon-bumen"
								 style="width: 25px; line-height: 30px;color: #888"/>
						</template>
						<el-option v-for="item in departmentList" :key="item.value"
											 :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="search-container-fn-input">
					<time-type-select
						class="time-select"
						:width="190"
						default-type="month"
						@change="timeTypeChange"/>
				</div>
			</div>
		</sticky>
		<!--数据统计-->
		<div class="gg-container" style="padding: 0; margin-top: 10px; margin-bottom: 15px; background-color: #f5f5f5;">
			<el-row :gutter="20" class="data-received-container">
				<el-col :span="12">
					<div class="grid-content bg-purple data-received-left">
						<div class="up">
							<i class="iconfont icon-shujux"></i>
							<span>数据统计</span>
						</div>
						<div class="down">
							<div class="item">
								<span class="title">销售总额</span>
								<span class="price">¥ 10008.00</span>
							</div>
							<div class="item">
								<span class="title">档案总数</span>
								<span class="price">260</span>
							</div>
							<div class="item">
								<span class="title">支付笔数</span>
								<span class="price">558</span>
							</div>
							<div class="item">
								<span class="title">成交笔数</span>
								<span class="price">123</span>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content bg-purple-light data-received-right">
						<div class="up">
							<i class="iconfont icon-daibanshixiang"></i>
							<span>待办事项</span>
						</div>
						<div class="down">
							<div class="item">
								<span class="title">待审核</span>
								<span class="price">20</span>
							</div>
							<div class="item">
								<span class="title">带绑定</span>
								<span class="price">12</span>
							</div>
							<div class="item">
								<span class="title">待完善</span>
								<span class="price">558</span>
							</div>
							<div class="item">
								<span class="title">待分配</span>
								<span class="price">99</span>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		
		<!-- 订单统计&数据汇总-->
		<div class="gg-container" style="padding: 0; margin-top: 10px; margin-bottom: 15px;  background-color: #f5f5f5;">
			<el-row :gutter="15" class="order-statistics-container">
				<el-col :span="14">
					<div class="grid-content bg-purple order-statistics-wrapper">
						<div class="up">
							<div class="title">
								<i class="iconfont icon-icon-order"></i>
								<span>订单统计</span>
							</div>
							<div class="choice-time-wrapper">
								<div class="choice-time"
										 :class="{'is-active': this.statisticsBarTime==='month'}"
										 @click="handleStatisticsBarTime('month')">近一月
								</div>
								<div class="choice-time"
										 :class="{'is-active': this.statisticsBarTime==='week'}"
										 @click="handleStatisticsBarTime('week')">近一周
								</div>
							</div>
						</div>
						<div class="down">
							<div class="item-statistical-chart" ref="statisticalChart" style="height:382px">
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="10">
					<div class="grid-content bg-purple-light data-summary-wrapper">
						<div class="up">
							<i class="iconfont icon-shuju9"></i>
							<span>数据统计</span>
						</div>
						<div class="down">
							<div class="item">对于数据收集统计的汇总</div>
							<el-divider></el-divider>
							<div class="item">新增10个客户,已分配6个,待分配4个</div>
							<el-divider></el-divider>
							<div class="item">跟进客户2780个,A类100个,B类80个,C类1200个,D类1400个</div>
							<el-divider></el-divider>
							<div class="item">销售客户100个客户,售后客户300个</div>
							<el-divider></el-divider>
							<div class="item">咨询订单100个,追访订单120个</div>
							<el-divider></el-divider>
							<div class="item">在服档案200个,预停档案100个,停服档案100个</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!--排行&占比-->
		<div class="gg-container" style="padding: 0;  background-color: #f5f5f5;">
			<el-row :gutter="15" class="rank-list-container">
				<el-col :span="7">
					<div class="grid-content bg-purple customer-rank-wrapper">
						<div class="up">
							<i class="iconfont icon-yonghu"></i>
							<span>用户排行</span>
						</div>
						<div class="header">
							<span>用户</span>
							<span>消费金额(元)</span>
						</div>
						<div class="customer-items">
							<div class="items">
								<div class="customer-info">
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-diyiming"></use>
									</svg>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">¥9999.00</span>
							</div>
							<div class="items">
								<div class="customer-info">
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-diyimingfuzhix"></use>
									</svg>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">8888.00</span>
							</div>
							<div class="items">
								<div class="customer-info">
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-disanming"></use>
									</svg>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">7777.00</span>
							</div>
							<div class="items">
								<div class="customer-info">
									<i class="iconfont icon-shuju9"></i>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">¥9999.00</span>
							</div>
							<div class="items">
								<div class="customer-info">
									<i class="iconfont icon-shuju9"></i>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">¥9999.00</span>
							</div>
							<div class="items">
								<div class="customer-info">
									<i class="iconfont icon-shuju9"></i>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">¥9999.00</span>
							</div>
						
						
						</div>
					</div>
				</el-col>
				
				
				<el-col :span="7">
					<div class="grid-content bg-purple market-wrapper">
						<div class="up">
							<i class="iconfont icon-paihangbang"></i>
							<span>销售排行</span>
						</div>
						<div class="header">
							<span>用户</span>
							<span>消费金额(元)</span>
						</div>
						<div class="customer-items">
							<div class="items">
								<div class="customer-info">
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-diyiming"></use>
									</svg>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">¥9999.00</span>
							</div>
							<div class="items">
								<div class="customer-info">
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-diyimingfuzhix"></use>
									</svg>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">8888.00</span>
							</div>
							<div class="items">
								<div class="customer-info">
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-disanming"></use>
									</svg>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">7777.00</span>
							</div>
							<div class="items">
								<div class="customer-info">
									<i class="iconfont icon-shuju9"></i>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">¥9999.00</span>
							</div>
							<div class="items">
								<div class="customer-info">
									<i class="iconfont icon-shuju9"></i>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">¥9999.00</span>
							</div>
							<div class="items">
								<div class="customer-info">
									<i class="iconfont icon-shuju9"></i>
									<el-avatar :size="30"
														 src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
									<span class="name">李莉莉</span>
								</div>
								<span class="price">¥9999.00</span>
							</div>
						</div>
					</div>
				</el-col>
				
				
				<el-col :span="10">
					<div class="grid-content bg-purple-light sales-percentage-wrapper">
						<div class="up">
							<i class="iconfont icon-icon-order"></i>
							<span>销售占比</span>
						</div>
						<div class="down">
							<div class="sales-percentage-chart" ref="salesPercentageChart" style="height:382px">
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	
	</div>
</template>

<script>
import Sticky from './components/sticky'
import TimeTypeSelect from './components/TimeTypeSelect'

export default {
  name: "CDMHomePage",
  components: {
    Sticky,
    TimeTypeSelect
  },
  mounted () {
    this._initStatisticalChart()
		this._initSalesPercentageChart()
    window.addEventListener("resize", this.chartStatisticalInstance.resize);
    window.addEventListener('resize', this.chartSalesPercentageInstance.resize)
  },
  created () {
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.chartStatisticalInstance)
    window.removeEventListener('resize', this.chartSalesPercentageInstance)
  },
  data () {
    return {
      filterValue: {
        dataType: 2,
        users: [],
        strucs: [],
        timeLine: {
          type: 'month',
          value: 'default'
        }
      },
      departmentList: ['仅本部门', '其他部门'],
      choiceDepartment: '',
      statisticsBarTime: 'month',
      nowTime: this.$moment().format("YYYY年MM月DD日")
    }
  },
  methods: {
    timeTypeChange (data) {
      console.log(data)
      this.filterValue.timeLine = data
      let type = data.type
      let value = data.value
      if (type === 'custom') {
        const startTime = data.startTime.split('.').join('-')
        const endTime = data.endTime.split('.').join('-')
        this.filterValue.timeLine.start_date = startTime
        this.filterValue.timeLine.end_date = endTime
        value = 'custom'
        type = ''
      }
      if (value === 'lastWeek') {
        value = 'last_week'
      } else if (value === 'lastMonth') {
        value = 'last_month'
      }
      this.filterValue.timeLine.value = type
      this.filterValue.timeLine.type = value

      this._getReportHospital(this.filterValue.timeLine)
    },
    handleSelectDepartment (v) {
      console.log(v)
    },
    _initStatisticalChart () {
      const initOption = {
        color: ['#2362fb', '#fcc810'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          data: ['支付金额', '成交金额'],
          itemWidth: 40,
          itemHeight: 20,
        },
        xAxis: {
          type: 'category',
          data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
          // data: this.barDataDiagnosisX
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '支付金额',
          type: 'bar',
          barWidth: '20%', //柱图宽度
          data: [10, 29, 8, 30, 5, 14]
          // data:  this.barDataDiagnosisPresY
        },
          {
            name: '成交金额',
            type: 'bar',
            barWidth: '20%', //柱图宽度
            data: [13, 19, 12, 20, 15, 4]
            // data: this.barDataDiagnosisEmrY
          }
        ]
      }
      this.chartStatisticalInstance = this.$echarts.init(this.$refs.statisticalChart)
      this.chartStatisticalInstance.showLoading()
      this.chartStatisticalInstance.setOption(initOption)
      this.chartStatisticalInstance.hideLoading();
    },
    _initSalesPercentageChart() {
      const initOption = {
        color: ['#2362fb', '#fcc810', '#17e24b'],
        title: {
          text: `数据时间: ${this.nowTime}`,
          textStyle: {
            color: '#666',
          },
          left: '35%',
          bottom: '0'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          itemWidth: 40,
          itemHeight: 20,
          data: ['售后', '售前']
        },
        series: [{
          name: '问诊来源',
          type: 'pie',
          radius: ['43%', '62%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: true,
            position: 'outside'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          // data: this.diagnosisFrom
          data: [
            { value: 1048, name: '售前' },
            { value: 735, name: '售后' },
          ]
        }]
      }
      this.chartSalesPercentageInstance = this.$echarts.init(this.$refs.salesPercentageChart)
      this.chartSalesPercentageInstance.setOption(initOption)
    },

    handleStatisticsBarTime (barTime) {
      console.log(barTime)
      this.statisticsBarTime = barTime
    }
  }
}
</script>

<style scoped lang="scss">
	::v-deep.time-select {
		.el-input__inner {
			height: 32px;
		}
		
		.el-icon-arrow-up {
			top: 10px
		}
	}
	
	.header-container {
		display: flex;
		justify-items: center;
	}
	
	/*数据统计区布局*/
	.el-row {
		margin-bottom: 20px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.el-col {
		border-radius: 4px;
	}
	
	.bg-purple-dark {
		// background: #99a9bf;
		background: #fff;
	}
	
	.bg-purple {
		// background: #d3dce6;
		background: #fff;
	}
	
	.bg-purple-light {
		// background: #e5e9f2;
		background: #fff;
	}
	
	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}
	
	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
	
	.data-received-container {
		.data-received-left {
			display: flex;
			flex-direction: column;
			justify-items: center;
			
			.up {
				display: flex;
				justify-items: center;
				align-items: center;
				margin: 15px;
				
				i {
					margin-right: 10px;
					color: #4EBBFC;
				}
				
				span {
					font-size: 15px;
					font-weight: bolder;
					color: #333;
				}
			}
			
			.down {
				display: flex;
				justify-items: center;
				align-items: center;
				margin: 0 15px 15px 15px;
				
				.item {
					flex: 1;
					display: flex;
					flex-direction: column;
					
					.title {
						font-size: 13px;
						color: #555;
						margin-bottom: 10px;
					}
					
					.price {
						font-size: 16px;
						font-weight: bolder;
						color: #4EBBFC;
					}
				}
			}
		}
		
		.data-received-right {
			display: flex;
			flex-direction: column;
			justify-items: center;
			
			.up {
				display: flex;
				justify-items: center;
				align-items: center;
				margin: 15px;
				
				i {
					margin-right: 10px;
					color: #2362FB;
				}
				
				span {
					font-size: 15px;
					font-weight: bolder;
					color: #333;
				}
			}
			
			.down {
				display: flex;
				justify-items: center;
				align-items: center;
				margin: 0 15px 15px 15px;
				
				.item {
					flex: 1;
					display: flex;
					flex-direction: column;
					
					.title {
						font-size: 13px;
						color: #555;
						margin-bottom: 10px;
					}
					
					.price {
						font-size: 16px;
						font-weight: bolder;
						color: #4EBBFC;
					}
				}
			}
		}
	}
	
	
	.order-statistics-container {
		.order-statistics-wrapper {
			padding: 15px;
			display: flex;
			flex-direction: column;
			
			.up {
				display: flex;
				align-items: center;
				justify-content: space-between;
				
				.title {
					i {
						margin-right: 10px;
						color: #15CE45;
					}
					
					span {
						font-size: 15px;
						font-weight: bolder;
						color: #333;
					}
				}
				
				.choice-time-wrapper {
					margin-right: 100px;
					width: 200px;
					display: flex;
					justify-content: space-around;
					align-items: center;
					
					.choice-time {
						padding-bottom: 3px;
						color: #555;
						font-weight: bold;
						font-size: 14px;
						border-bottom: 4px solid transparent;
						
						&:hover {
							cursor: pointer;
						}
					}
					
					.is-active {
						border: 0 transparent;
						border-bottom: 4px solid #2362fb;
						border-bottom-right-radius: 0;
						border-bottom-left-radius: 0;
					}
				}
			}
			
			.down {
				.item-statistical-chart {
				}
			}
		}
		
		.data-summary-wrapper {
			height: 438px;
			
			.up {
				padding: 15px;
				
				i {
					margin-right: 10px;
					color: #FC9310;
				}
				
				span {
					font-size: 15px;
					font-weight: bolder;
					color: #333;
				}
			}
			.down {
				padding: 0 17px 0 17px;
				.item {
					font-size: 14px;
					color: #555
				}
			}
		}
	}
	
	
	.rank-list-container {
		height: 438px;
		
		.customer-rank-wrapper {
			height: 438px;
			.up {
				padding: 15px 15px 10px 15px;
				
				i {
					margin-right: 10px;
					color: #BC63FC;
				}
				
				span {
					font-size: 15px;
					font-weight: bolder;
					color: #333;
				}
			}
			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 15px 0 15px;
				span{
					font-size: 13px;
					color: #555;
					font-weight: bolder;
				}
			}
			.customer-items{
				display: flex;
				flex-direction: column;
				padding: 10px 15px 0 15px;
				.items{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 15px;
					.customer-info{
						display: flex;
						justify-content: space-around;
						align-items: center;
						width: 120px;
						.name{
							color: #333;
							font-size: 14px;
						}
					}
					.price{
						color: #333;
						font-size: 14px;
					}
				}

			}
		}
		
		.market-wrapper{
			height: 438px;
			.up {
				padding: 15px 15px 10px 15px;
				
				i {
					margin-right: 10px;
					color: #FCC810;
				}
				
				span {
					font-size: 15px;
					font-weight: bolder;
					color: #333;
				}
			}
			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 15px 0 15px;
				span{
					font-size: 13px;
					color: #555;
					font-weight: bolder;
				}
			}
			.customer-items{
				display: flex;
				flex-direction: column;
				padding: 10px 15px 0 15px;
				.items{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 15px;
					.customer-info{
						display: flex;
						justify-content: space-around;
						align-items: center;
						width: 120px;
						.name{
							color: #333;
							font-size: 14px;
						}
					}
					.price{
						color: #333;
						font-size: 14px;
					}
				}
				
			}
		}
		
		.sales-percentage-wrapper{
			padding: 15px;
			display: flex;
			flex-direction: column;
			.up{
				display: flex;
				align-items: center;
				height: 26px;
				i {
					margin-right: 10px;
					color: #FC63F5;
				}
				span {
					font-size: 15px;
					font-weight: bolder;
					color: #333;
				}
			}
			
			.down {
				.sales-percentage-chart {
				}
			}
		}
		
	}
	
	.icon {
		width: 1em;
		height: 1em;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
	
	
	
	
</style>
